'use client'


import HeroImage from "../../assets/HeroSubject.png"
import Navbar from "../shared/Navbar"

const HeroSection = () => {

  return (
    <div style={{height:"661px"}} className="bg-gray-900">
      
      <Navbar/>

      <div className="relative isolate pt-14">
        <img
          alt=""
          src={HeroImage}
          className="absolute inset-0 -z-10 object-cover heroImage"
        />
      
        <div className="mx-auto max-w-4xl py-32">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
           
          </div>
          <div className="md:text-center text-left md:px-0 px-12">
            <h1 className="font-pt-sans mt-16 md:text-64px font-bold tracking-normal text-white text-5xl md:leading-4rem">
                We believe in <br/> Building Bharat Future Ready
            </h1>
            <p className="font-pt-sans text-2xl mt-6 leading-8 text-gray-100">
            Empowering India with technological advancements, social progress and economic growth to ensure a sustainable future for all
            </p>
            
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default HeroSection;