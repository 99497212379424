import HeroSection from "../components/about/HeroSection";
import Business from "../components/about/Business";
import Mission from "../components/about/Mission";
import Values from "../components/about/Values";
import Team from "../components/about/Team";
import Members from "../components/about/Members";
import Footer from "../components/shared/Footer";

function About() {
  return (
    <div className="flex flex-col">
      <HeroSection />
      <Business />
      <Mission />
      <Values />
      <Team />
      {/* <Members/> */}
      <Footer />
    </div>
  );
}

export default About;
